import { apiPath } from "@/config"

import axios from "axios";
const api = axios.create({
    baseURL: apiPath,
});

api.interceptors.request.use(

    config => {
        var token = localStorage.getItem("token");
        if (token) {
            config.headers["token"] = token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;