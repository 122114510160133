import { useMemo, memo, useState, useEffect, useRef } from "react"

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const MapPicker = ({ value, onChange }) => {

    const [mapValue, setMapValue] = useState({});
    const [pin, setPin] = useState(null);
    const [cuurentPostionButton, setCuurentPostionButton] = useState(null);

    const [showInput, setShowInput] = useState(false)
    const [modal, setModal] = useState(false);
    const [googleMap, setGoogleMap] = useState(null);
    const txtMap = useRef("mapUrl");
    useEffect(() => {

        var newValue = value ?? {};
        if (JSON.stringify(newValue) != JSON.stringify(mapValue))
            setMapValue(newValue)
    }, [value])

    useEffect(() => {
        if (JSON.stringify(mapValue) != JSON.stringify(value)) {
            onChange(mapValue);
        }

    }, [mapValue])




    const toggle = () => setModal(!modal);
    const defaultProps = {
        center: {
            lat: value?.lat ?? 11.5253032,
            lng: value?.lng ?? 107.8320071
        },
        zoom: 18
    };

    useEffect(() => {
        if (cuurentPostionButton) {
            cuurentPostionButton.addEventListener("click", getCurrentLocation);
        }
    }, [googleMap, cuurentPostionButton])

    const handleApiLoaded = async (map, maps) => {
        setGoogleMap({ map, maps });
        // Create the DIV to hold the control.
        const centerControlDiv = document.createElement("div");
        centerControlDiv.className = "p-2";
        // Create the control.
        const centerControl = createCenterControl(map);

        // Append the control to the DIV.
        centerControlDiv.appendChild(centerControl);
        map.controls[maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
        setCuurentPostionButton(centerControl);

        const input = document.getElementById("pac-input");
        const searchBox = new maps.places.SearchBox(input);

        map.controls[maps.ControlPosition.TOP_LEFT].push(input);
        // const { AdvancedMarkerElement } = await maps.importLibrary("marker");
        // const { Map, InfoWindow } = await maps.importLibrary("maps");

        // const { Place } = await maps.importLibrary("places")
        // const infoWindow = new InfoWindow();
        // const marker = new AdvancedMarkerElement({
        //     map,
        //     position: { lat: 11.525585833327812, lng: 107.83600971357156 },
        //     gmpDraggable: true,
        //     title: "This marker is draggable.",
        // });
        // const options = {
        //     fields: ["formatted_address", "geometry", "name"],
        //     strictBounds: false,
        // };
        // const input = document.getElementById("abc");
        // const autocomplete = new maps.places.Autocomplete(input, options);

        // console.log("marker", marker)
        // marker.addListener("dragend", (event) => {
        //     // const position = marker.position;
        //     // infoWindow.close();
        //     // infoWindow.setContent(
        //     //     `Pin dropped at: ${position.lat}, ${position.lng}`,
        //     // );
        //     //infoWindow.open(marker.map, marker);
        // });
        // use map and maps objects
    };
    const dropPin = async () => {
        const { map, maps } = googleMap;
        const point = map.getCenter();

        setMapValue({ ...mapValue, lat: point.lat(), lng: point.lng() })
        // createPin(point.lat(), point.lng());
    }

    const createPin = async (lat, lng) => {
        console.log("create pin")
        const { map, maps } = googleMap;
        const { AdvancedMarkerElement } = await maps.importLibrary("marker");
        const { Map, InfoWindow } = await maps.importLibrary("maps");
        const infoWindow = new InfoWindow();
        const marker = new AdvancedMarkerElement({
            map,
            position: { lat: lat, lng: lng },
            gmpDraggable: true,

        });
        marker.addListener("dragend", (event) => {
            const position = marker.position;
            setMapValue({ ...mapValue, lat: position.lat, lng: position.lng });
        });
        setPin(marker);


    }

    useEffect(() => {

        if (!modal)
            return;
        if (!googleMap)
            return;

        if (!mapValue.lat)
            return;
        console.log("pin", pin);
        if (pin)
            return;
        createPin(mapValue.lat, mapValue.lng)
        //init map and pin
    }, [modal, googleMap, mapValue, pin])

    const removePin = () => {
        setMapValue({});
        if (pin) {
            pin.setMap(null);
            setPin(null);
        }


    }


    const createCenterControl = (map) => {
        const controlButton = document.createElement("button");

        // Set CSS for the control.
        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "2px solid #fff";
        controlButton.style.borderRadius = "3px";
        controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlButton.style.color = "rgb(25,25,25)";
        controlButton.style.cursor = "pointer";
        controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
        controlButton.style.fontSize = "16px";
        controlButton.style.lineHeight = "38px";
        controlButton.style.margin = "8px 0 22px";
        controlButton.style.padding = "0 5px";
        controlButton.style.textAlign = "center";
        controlButton.innerHTML = `<i class="fa-solid fa-location-crosshairs p-2"></i>`;
        controlButton.title = "Click to recenter the map";
        controlButton.type = "button";
        // Setup the click event listeners: simply set the map to Chicago.

        return controlButton;
    }



    const getCurrentLocation = () => {


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setCurrentLocation,

                (err) => {
                    alert("Không thể lấy vị trí hiện tại 2" + JSON.stringify(err))
                },
            );
        } else {
            // Browser doesn't support Geolocation
            alert("Không thể lấy vị trí hiện tại")
        }
    }

    const setCurrentLocation = (position) => {
        const { map, maps } = googleMap;
        const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        setMapValue({ ...mapValue, lat: position.coords.latitude, lng: position.coords.longitude })
        map.setCenter(pos);
    }
    const pasteText = async () => {
        try {
            const text = await navigator.clipboard.readText()
            txtMap.current.value =text;
          } catch (error) {
            console.log('Failed to read clipboard');
          }
  
    }


    return (
        <div className="mb-3">
            <span className="btn btn-link " onClick={e => setShowInput(true)}>  <i className="fa-solid  fa-map-pin me-2"></i>Vị trí</span>
            {showInput &&

                <div class="mb-3">
                    <label class="form-label"> Google map</label>
                    <div className="input-group ">
                        <input type="text" class="form-control" placeholder="Copy link chia sẻ trên Google Map và dán" value={value} onChange={onChange} ref={txtMap} />
                        <span className="btn btn-outline-primary" onClick={pasteText} ><i className="fa-solid fa-paste"></i></span>
                    </div>
                    <div>
                        <span className="btn btn-link" onClick={e => window.open('https://www.youtube.com/watch?v=VIVdcNZHK8c', "_blank")}>Xem hướng dẫn cách ghim vị trí</span>
                    </div>

                    <div className="mt-2">
                        <span className="btn btn-link" onClick={e => window.open('https://www.google.com/maps/@10.7552928,106.3655777,10z?entry=ttu', "_blank")}>Mở Google Map</span>
                    </div>
                </div>
            }
            {value &&


                <span className="btn btn-link" onClick={e => window.open(`http://maps.google.com/maps?q=${value.lat},${value.lng}`, "_blank")}>Xem trên Google Map</span>

            }

            {/* <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Chọn vị trí</ModalHeader>
                <ModalBody>
                    <input
                        id="pac-input"
                        class="controls"
                        type="text"
                        placeholder="Search Box"
                    />
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCAuXPKP-pvH6yuVIFXb8sz7QfQJtfytMo", libraries: "places" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        options={{ mapId: "f4146413e2430c30" }}
                    >

                    </GoogleMapReact>

                </ModalBody>
                <ModalFooter>
                    {mapValue.lat ?
                        <Button color="primary" onClick={removePin} type="link">
                            Bỏ Ghim
                        </Button>
                        :
                        <Button color="primary" onClick={dropPin} >
                            Ghim Vị Trí
                        </Button>
                    }


                    <Button color="primary" onClick={toggle}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal> */}
        </div>

    )

}




export default memo(MapPicker)


