import { useMemo, memo, useState, useEffect, useRef } from "react"

import ImagesPicker from "./Images"

const NavigationPicker = ({ value, onChange }) => {

    const [data, setData] = useState(value ?? { steps: [] });
    useEffect(() => {
        setData(value ?? { steps: [] })

    }, [value])
    const addDirection = () => {
        var newData = { ...data };
        newData.steps.push({ title: "", images: [] })
        setData(newData);
        onChange(newData)
    }

    const stepChanged = (step, index) => {
        var newData = { ...data };
        newData.steps[index] = step;
        setData(newData);
        onChange(newData)
    }
    return (
        <div className="mb-3">

            <div className="card mt-2">
                <div className="card-body">
                    <div className="mt-3">
                        <h5 className="card-title"> Chỉ đường</h5>
                    </div>
                    {data.steps.map((step, index) => {
                        return <Step data={step} index={index} onChange={stepChanged} ></Step>
                    })}

                    <div className="mt-2">
                        <span className="btn btn-link " onClick={addDirection}> <i className="fa-solid fa-route  me-2"></i> Thêm chỉ dẫn</span>
                    </div>
                </div>
            </div>

        </div>

    )

}

const Step = ({ data, collapse, index, onDelete, onChange }) => {
    const onTextChange = (e) => {
        var data2 = { ...data };
        data2.title = e.target.value;
        onChange(data2, index);
    }

    const onImagesChange = (images) => {
        var data2 = { ...data };
        data2.images = images;
        onChange(data2, index);
    }

    return (


        <div  >
            <div className='mt-2'>
                <div class="mb-3">
                    <label class="form-label"> {index == 0 ? `${index + 1}. Bắt đầu` : `${index + 1}. Điểm kế tiếp`} </label>
                    <textarea value={data.title} onChange={onTextChange} type="text" class="form-control" placeholder={index == 0 ? "Bắt đầu từ một điểm dễ tìm như nhà hàng, bến xe" : "Chỉ dẫn từ điểm trước đó ví dụ rẽ trái ở ngã 3 thứ nhất"} />
                </div>
                <ImagesPicker value={data.images} onChange={onImagesChange}></ImagesPicker>
            </div>

        </div>

    )
}





export default memo(NavigationPicker)


