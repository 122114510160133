import { useState, useEffect, memo, useContext } from "react";
import { AppContext } from "../app";
import { useNavigate } from "react-router-dom";
import * as UserService from "@services/user"
import * as PlaceService from "@services/place"
import LoadingDiv from "@components/loading"
import List from "@components/list-place"

function Home() {
    const nav = useNavigate();
    const { setHeader } = useContext(AppContext)

    const [myPlaces, setMyPlaces] = useState([])
    const [loadingMyPlaces, setLoadingMyPlaces] = useState(true)
    const [showSearch, setShowSearch] = useState(false)
    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
        loadMyPlaces();

    }, [])

    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
    }, [showSearch])



    const loadMyPlaces = async () => {
        setLoadingMyPlaces(true);
        var lst = await UserService.listPlace({});
        setLoadingMyPlaces(false);
        if (lst) {
            setMyPlaces(lst);
        }
    }




    const getHeader = () => {
        return (
            <>
                <a className="navbar-brand" href="#">
                    <img className="me-2" src="https://storage.googleapis.com/cubebio/default/map.png" style={{ width: 32, height: 32 }}></img> Chỉ đường của tôi
                </a>

            </>
        )
    }
    const noData = (
        <div>
            <div className="m-2 d-flex  justify-content-center">

                <img src="https://storage.googleapis.com/cubebio/default/nodata.svg" class="img-fluid" alt="..."></img>
            </div>
            <div className="m-2 text-center">
                Bạn chưa có chỉ đường nào.
            </div>
            <div className="m-2 text-center">
                Hãy tạo một chỉ đường  mới và chia sẻ với bạn bè hoặc shipper.
            </div>
            <div className="m-2 text-center">
                Giúp mọi người tìm đường dễ dàng.
            </div>

        </div>
    )

    return (
        <div className="p-2">



            <div className="mt-2">
                <List places={myPlaces ?? []} isLoading={loadingMyPlaces} noData={noData}></List>


            </div>
            <div className="m-2 d-flex  justify-content-center">
                <span className="btn btn-primary" onClick={e => nav("/place/edit/0")}> Tạo chỉ đường</span>
            </div>
            <div className="m-2 d-flex  justify-content-center">
                <span className="btn btn-link" onClick={e => nav("/place/Z90bwxbjDV")}>   Xem chỉ đường mẫu</span>
            </div>
        </div>
    )
}



export default Home;