//const apiPath ="https://api.myitemsinventory.app/";
var apiPath = "http://192.168.1.223:3068/";
var socketPath = "http://192.168.1.223:3068/";
apiPath = "https://app.ochonao.app/";
socketPath = "https://app.ochonao.app/";
if (process.env.NODE_ENV === 'production') {
    apiPath = "https://app.ochonao.app/";
    socketPath = "https://app.ochonao.app/";

}

export { apiPath, socketPath }