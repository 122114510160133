import { useMemo, memo, useState, useEffect, useRef } from "react"
import ImageGird from "./ImageGird"
import { upload } from "@/services/file"
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';


import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Images = ({ value, onChange }) => {

    const [data, setData] = useState(value ?? []);
    const [uploading, setUploading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [imageEdit, setImageEdit] = useState(false);
    useEffect(() => {
        setData(value ?? [])
    }, [value])

    const fileInput = useRef("file");


    const selectFile = () => {
        fileInput.current.click();
    }

    const fileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }
        setUploading(true);
        // setShowEdit(true)
        //setUploading(false);
        ///var url = URL.createObjectURL(selectedFile);
        // setImageEdit(url);
        //return;
        var image = null;
        try {
            image = await upload(selectedFile);
        } catch (error) {

        }

        setUploading(false);
        if (!image) {
            alert("Không thể upload file")
            return;
        }


        const newData = [...data];
        newData.push(image)

        onChange(newData);
        fileInput.current.value = null;
    }

    const onDelete = (index) => {
        console.log("delye")
        const newData = [...data];

        newData.splice(index, 1)

        onChange(newData);
    }




    return (

        <div>
            <ImageGird data={data} onDelete={onDelete} ></ImageGird>
            <div>
                <input type="file" ref={fileInput} onChange={fileChange} style={{ display: "none" }} accept="image/*"></input>
                {
                    uploading ?

                        <span className="btn btn-link " onClick={selectFile}> <span className="me-2">Uploading </span>
                            <div class="spinner-border text-primary spinner-grow-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </span>
                        :
                        <span className="btn btn-link " onClick={selectFile}> <i className="fa-solid fa-image me-2"></i>Hình ảnh

                        </span>

                }

            </div>
            <EditImageModal visible={showEdit} image={imageEdit} onClose={e => setShowEdit(false)}></EditImageModal>

        </div>

    )

}

const EditImageModal = ({ visible, image, onClose }) => {
    const [isShow, setIsShow] = useState(visible);
    useEffect(() => {
        setIsShow(visible);
    }, [visible])
    useEffect(() => {
        if (!isShow) {
            onClose();
        }
    }, [isShow])

    const toggleShow = () => {
        setIsShow(!visible);
    }
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);

    const openImgEditor = () => {
        setIsImgEditorShown(true);
    };

    const closeImgEditor = () => {
        setIsImgEditorShown(false);
    };
    return (


        <Modal isOpen={isShow} fullscreen >

            <ModalBody>
                <div>


                    <FilerobotImageEditor
                        source={image}
                        noPresets={true}
                        onSave={(editedImageObject, designState) =>
                            console.log('saved', editedImageObject, designState)
                        }
                        onClose={toggleShow}
                        annotationsCommon={{
                            fill: '#FF0000',
                            stroke: '#FF0000',
                            strokeWidth: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 0,
                            shadowColor: '#FF0000',
                            shadowOpacity: 1,
                            opacity: 1,
                        }}
                        Text={{ text: 'Ghi Chú' }}


                        tabsIds={[TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
                        defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                        //defaultToolId={TOOLS.ANNOTATE} // or 'Text'
                        removeSaveButton={true}

                    />

                </div>
            </ModalBody>
            <ModalFooter>


                <Button color="secondary">
                    Đóng
                </Button>
            </ModalFooter>
        </Modal>

    )
}




export default memo(Images)


