import api from "@/api"
const upload = async (file) => {
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "file",
        file
    );

    var { data } = await api.post("api/user/file/upload", formData);

    if (data.code == 1) {
        return data.data;
    }
    return null;

}

export { upload }





