import { useState, useEffect, useContext, memo } from "react";
import Place from "@components/place"
import Home from "@components/home"
import Recently from "@components/recently"
import Search from "@components/search"
import PlaceEditor from "@components/place-edit"

import { Routes, Route, useParams, useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "./index";
import LoadingDiv from "@components/loading"
import * as AuthService from "@services/auth"
function PlaceRouter() {
    const params = useParams();
    return (
        <Place id={params.id}></Place>
    )
}

function HomeRouter() {
    return (
        <Home></Home>
    )
}

function RecentlyRouter() {
    return (
        <Recently></Recently>
    )
}

function SearchRouter() {
    return (
        <Search></Search>
    )
}




function PlaceEditRouter() {
    const params = useParams();
    return (
        <PlaceEditor id={params.id}></PlaceEditor>
    )
}



function Layout() {
    const { header, setUser } = useContext(AppContext);
    const [isLoged, setIsloged] = useState(false);
    useEffect(() => {
        login();

    }, []);
    const login = async () => {
        var auth = await AuthService.logIn();
        if (auth) {
            setIsloged(true);
            setUser(auth)
        }
    }

    return (

        <div className="container p-0">

            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    {header ?
                        <>{header}</>
                        :
                        <>
                            <a className="navbar-brand" href="#">Chỉ đường</a>
                        </>
                    }
                </div>
            </nav>
            <div style={{minHeight:"100vh"}}>
                {isLoged ?
                    <Routes>
                        <Route path="/place/:id" element={<PlaceRouter></PlaceRouter>} />
                        <Route path="/place/edit/:id" element={<PlaceEditRouter></PlaceEditRouter>} />
                        <Route path="/recently" element={<RecentlyRouter></RecentlyRouter>} />
                        <Route path="/search" element={<SearchRouter></SearchRouter>} />
                        <Route path="/" element={<HomeRouter></HomeRouter>} />

                    </Routes>
                    :
                    <>
                        <LoadingDiv></LoadingDiv>
                    </>
                }

            </div>



            <Footer></Footer>
            <BottomBar></BottomBar>
        </div>
    );
}


const BottomBar = memo(() => {
    let location = useLocation();


    const [activeTab, setActiveTab] = useState("home");

    useEffect(() => {

        switch (location.pathname) {
            case "/":
                setActiveTab("home")
                break;
            case "/recently":
                setActiveTab("recently")
                break;
            case "/search":
                setActiveTab("search")
                break;


            default:
                setActiveTab("home");
        }
    },

        [location])

    return (
        <div className="fixed-bottom bg-light p-2" style={{ width: "100%" }}>
            <div className="row">
                <div className="col">
                    <BottomBarIcon icon="fa-solid fa-house" url="" active={"home" == activeTab}></BottomBarIcon>
                </div>
                <div className="col">
                    <BottomBarIcon icon="fa-solid fa-route" url="./recently" active={"recently" == activeTab}></BottomBarIcon>
                </div>
                <div className="col">
                    <BottomBarIcon icon="fa-solid fa-magnifying-glass" url="./search" active={"search" == activeTab}></BottomBarIcon>
                </div>
            </div>
        </div>
    )
})


const BottomBarIcon = memo(({ icon, url, active }) => {
    const nav = useNavigate();
    return (
        <div className="d-flex justify-content-center  align-items-center" onClick={e => nav(url)}>
            <div className={active ? "d-flex justify-content-center bg-primary align-items-center" : "d-flex justify-content-center  align-items-center"} style={{ height: 32, width: 32, borderRadius: 16, borderWidth: 1 }}>
                {active ?
                    <i className={icon} style={{ color: "white" }}></i>
                    :
                    <i className={`${icon} text-primary `}></i>
                }

            </div>
        </div>
    )
})



const Footer = memo(() => {
    return (
        <div class="container">
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div class="col-md-4 d-flex align-items-center">
                    <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">

                    </a>
                    <span class="mb-3 mb-md-0 text-muted">© 2024 ochonao.app</span>
                </div>

                <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li class="ms-3"><a class="text-muted" href="https://www.facebook.com/CubeTechDL" target="_blank"><i class="fa-brands fa-facebook"></i></a ></li>
    
                </ul>
            </footer>
        </div>
    )
})



export default Layout;
