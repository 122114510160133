import { useMemo, memo, useState, useEffect, useRef } from "react";
import NavigationPicker from "./NavigationPicker";

const NavigationsPicker = ({ value, onChange }) => {

    const [data, setData] = useState(value ?? []);
    useEffect(() => {
        setData(value ?? [])
        console.log("valuevaluevaluevalue", value)
    }, [value])
    const onNavigationChange = (nav, index) => {
        var newData = [...data];
        newData[index] = nav;
        onChange(newData);
    }



    return (

        <>
            {data.map((nav, index) => {
                return (
                    <NavigationPicker value={nav} onChange={nav2 => { onNavigationChange(nav2, index) }}></NavigationPicker>
                )
            })}
        </>
    )

}


export default memo(NavigationsPicker)


