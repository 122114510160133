import { useState, useEffect, memo, useContext } from "react";
import { AppContext } from "../app";
import { useNavigate } from "react-router-dom";
import * as UserService from "@services/user"
import * as PlaceService from "@services/place"
import LoadingDiv from "@components/loading"
import Item from "./Item"
function List({ places, isLoading, noData }) {
    const nav = useNavigate();
    return (
        <div className="p-2">

            {isLoading ?

                <LoadingDiv></LoadingDiv>
                :
                <>
                    {places.map(place => {
                        return <Item data={place}></Item>

                    })}
                    {places.length === 0 &&
                        <>
                            {noData ?
                                <>
                                    {noData}
                                </>
                                :
                                <>
                                    <div className="m-2 d-flex  justify-content-center">

                                        <img src="https://storage.googleapis.com/cubebio/default/nodata_2.svg" class="img-fluid" alt="..." style={{width:200, height:"auto"}}></img>
                                    </div>
                                    <div className="m-2 text-center">
                                        No data
                                    </div>


                                </>
                            }
                        </>
                    }
                </>

            }





        </div>
    )
}




export default memo(List);