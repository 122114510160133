import { useState, useMemo, memo } from "react"

import { resizeUrl } from "@services/image"
import Lightbox from "yet-another-react-lightbox";
import PhotoAlbum from "react-photo-album";
import "yet-another-react-lightbox/styles.css";

const ImageGird = ({ data }) => {
    const [carouselID, setcarouselID] = useState('carousel' + (Math.round(Math.random() * 10000)))
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(-1);
    if (!data) {

        return null;
    }
    if (data.length <= 0) {

        return null;
    }

    const slides = data.map(x => {
        return {
            src: x.url,
            width: x.size?.width ?? 800,
            height: x.size?.height ?? 600,
            title: "step",
            description: "ok llalal"
        }
    })

    return (
        <div>

            <PhotoAlbum layout="rows" photos={slides} onClick={({ index: current }) => setIndex(current)} />
            <Lightbox
                index={index}
                slides={slides}
                open={index >= 0}
                close={() => setIndex(-1)}
            />
        </div>
    )

    return (




        <div id={carouselID} className="carousel slide mt-2" data-bs-ride="carousel">


            <div className="carousel-inner">

                {data.map((item, index) => {
                    var active = ''
                    if (index == 0) {
                        active = 'active';
                    }
                    return (
                        <div className={`carousel-item ${active}`}>
                            <img src2={resizeUrl(item.url, 600)} src={item.url} style={{ width: "100%", height: "auto" }}></img>
                            {item.title &&
                                <div className="carousel-caption d-md-block">
                                    <h5>{item.title}</h5>

                                </div>
                            }

                        </div>
                    )
                })}



            </div>


            <button className="carousel-control-prev" type="button" data-bs-target={`#${carouselID}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#${carouselID}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )

}

export default memo(ImageGird)


