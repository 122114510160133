import { useState, useEffect, memo, useContext } from "react";
import FakeData from "@services/fake"
import ImageGird from "@components/image-gird"
import { json, useNavigate } from "react-router-dom";
import Navigation from "./Navigation"
import ImagesPicker from "./Images"
import MapPicker from "./MapPicker"
import NavigationsPicker from "./NavigationsPicker"

import { AppContext } from "../app";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { newID } from "@/common"
import * as UserService from "@services/user"
import LoadingDiv from "@components/loading"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
function Place({ id }) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleteting, setDeleteting] = useState(false);
    const [showDeleteBox, setShowDeleteBox] = useState(false)
    const { setHeader } = useContext(AppContext)
    const nav = useNavigate();
    const [viewPlaceID, setViewPlaceID] = useState(null)

    console.log("id", id);
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors },
        control,
        trigger,
        reset
    } = useForm({ values: { title: "", navigations: [{ title: "", steps: [{ title: "", images: [] }] }] } })


    useEffect(() => {
        if (id == 0) {
            const id = newID();
            var initNew = { id: id, title: "", navigations: [{ title: "", steps: [{ title: "", images: [] }] }] };
            reset(initNew)
        } else {
            loadData();
        }
    }, [])


    const onSubmit = (data) => console.log(data)

    useEffect(() => {
        console.log("id", id);
        if (id != 0) {
            setViewPlaceID(id)
        } else {
            setViewPlaceID(null)
        }

    }, [id]);

    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
    }, [saving, data, id, viewPlaceID])





    const loadData = async () => {
        setLoading(true)
        var place = await UserService.getPlaceByID(id);
        reset(place);
        setLoading(false)

    }

    const toggleShowDeletebox = () => {
        setShowDeleteBox(!showDeleteBox)
    }

    const deletePlace = async () => {
        setDeleteting(true);
        await UserService.deletePlace(id);
        setDeleteting(false);
        nav("/");
    }

    const getHeader = () => {

        return (
            <>
                <button className="btn btn-outline-primary my-2 my-sm-0" onClick={e => nav("/")} ><i className="fa-solid fa-house"></i></button>
                <a className="navbar-brand" href="#"> {id == 0 ? "Điểm mới" : "Chỉnh sửa"}</a>
                {saving ?
                    <button className="btn btn-outline-primary my-2 my-sm-0 " disabled >
                        <span className="me-2">Saving</span>

                        <div class="spinner-border text-primary spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </button>
                    :

                    <div>


                        {viewPlaceID &&
                            <button className="btn btn-outline-primary my-2 my-sm-0 me-4 " onClick={e => nav(`/place/${viewPlaceID}`)}><i className="fa-regular fa-eye" ></i></button>
                        }

                        <button className="btn btn-outline-primary my-2 my-sm-0" onClick={save}><i className="fa-solid fa-floppy-disk" ></i></button>
                    </div>




                }

            </>
        )
    }

    const save = async () => {
        var a = await trigger();
        if (!a) {
            alert("Thông tin nhập không hợp lệ, Vui lòng kiểm tra lại thông tin đã nhập");
            return;
        }
        setSaving(true);
        var place = getValues();
        await UserService.savePlace(place);
        setSaving(false);
        setViewPlaceID(place.id)

    }

    if (loading) {
        return (
            <LoadingDiv></LoadingDiv>
        )
    }


    console.log("ee", errors);
    return (
        <form onSubmit={handleSubmit(onSubmit)}   >
            <div>

                <div className='p-1'>
                    <div className="card">

                        <div className="card-body">
                            <h5 className="card-title">Địa chỉ</h5>

                            <div>

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{ required: "Bạn phải nhập tên", maxLength: { value: 500, message: "Tên phải dưới 500 ký tự" } }}

                                    render={({ field }) => (
                                        <div class="mb-3 has-validation">
                                            <label class="form-label">   <i className="fa-solid fa-location-dot me-2"></i>Tên</label>
                                            <input type="text" class="form-control" placeholder="Tên địa điểm ví dụ Shop Tina, Hair Salon..."  {...field} />
                                            <div className="invalid-feedback" style={{ display: errors.title ? "block" : "none" }}>
                                                {errors.title?.message}
                                            </div>
                                        </div>
                                    )

                                    }
                                />

                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{ maxLength: { value: 500, message: "Địa chỉ phải dưới 500 ký tự" } }}
                                    render={({ field }) => (
                                        <div class="mb-3">
                                            <label class="form-label">   <i className="fa-solid fa-road me-2"></i>Địa chỉ</label>
                                            <input type="text" class="form-control" placeholder="Địa chỉ của địa điểm"  {...field} />
                                            <div className="invalid-feedback" style={{ display: errors.address ? "block" : "none" }}>
                                                {errors.address?.message}
                                            </div>
                                        </div>
                                    )

                                    }
                                />


                                <Controller
                                    name="phone_number"
                                    control={control}
                                    rules={{ maxLength: { value: 25, message: "Số điện thoại phải dưới 25 ký tự" } }}
                                    render={({ field }) => (
                                        <div class="mb-3">
                                            <label class="form-label">   <i className="fa-solid fa-phone  me-2"></i>Số điện thoại</label>
                                            <input type="text" class="form-control" placeholder="Số điện thoại" {...field} />
                                            <div className="invalid-feedback" style={{ display: errors.phone_number ? "block" : "none" }}>
                                                {errors.phone_number?.message}
                                            </div>
                                        </div>
                                    )

                                    }
                                />

                                <Controller
                                    name="note"
                                    control={control}
                                    rules={{ maxLength: { value: 500, message: "Ghi chú phải dưới 500 ký tự" } }}
                                    render={({ field }) => (
                                        <div class="mb-3">
                                            <label class="form-label">   <i className="fa-regular fa-note-sticky me-2"></i>Ghi Chú</label>
                                            <textarea type="text" class="form-control" placeholder="Cổng nhà màu xanh, nhà màu hồng ..."  {...field} />
                                            <div className="invalid-feedback" style={{ display: errors.note ? "block" : "none" }}>
                                                {errors.note?.message}
                                            </div>
                                        </div>
                                    )

                                    }
                                />




                            </div>

                            <Controller
                                name="images"
                                control={control}
                                render={({ field }) => (

                                    <ImagesPicker {...field}></ImagesPicker>
                                )

                                }
                            />


                            <Controller
                                name="googlemap_link"
                                control={control}
                                render={({ field }) => (

                                    <MapPicker {...field}></MapPicker>
                                )

                                }
                            />











                        </div>
                    </div>




                    <Controller
                        name="navigations"
                        control={control}
                        render={({ field }) => (

                            <NavigationsPicker {...field}></NavigationsPicker>
                        )

                        }
                    />

                    {id != 0 &&
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="mt-3">
                                    <h5 className="card-title"> Thông tin thêm</h5>
                                </div>

                                <div className="mt-2">
                                    <span className="btn btn-link text-danger " onClick={toggleShowDeletebox}>Xoá địa điểm</span>
                                </div>




                            </div>
                        </div>
                    }



                </div>




            </div >

            <Modal isOpen={showDeleteBox} toggle={toggleShowDeletebox} >
                <ModalHeader toggle={toggleShowDeletebox}>Xoá</ModalHeader>
                <ModalBody>
                    <div>
                        Bạn có chắc chắn muốn xoá địa điểm này?

                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                        deleteting ?
                            <button className="btn btn-outline-primary my-2 my-sm-0 " disabled >
                                <span className="me-2">Saving</span>

                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </button>
                            :
                            <Button color="danger" onClick={deletePlace}>
                                Xoá
                            </Button>

                    }

                    <Button color="secondary" onClick={toggleShowDeletebox}>
                        Đóng
                    </Button>
                </ModalFooter>
            </Modal>

        </form >
    );
}

export default memo(Place);
