import { useState, useEffect, memo, useContext } from "react";
import FakeData from "@services/fake"
import ImageGird from "@components/image-gird"
import { useNavigate } from "react-router-dom";
import Navigation from "@components/navigation"
import { AppContext } from "../app";
import LoadingDiv from "@components/loading"
import * as PlaceService from "@services/place";
import * as UserService from "@services/user"

function Place({ id }) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const { setHeader, user } = useContext(AppContext)
    const nav = useNavigate();
    useEffect(() => {

        loadData();


    }, [id]);

    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
    }, [data])



    const loadData = async () => {
        setLoading(true)
        var place = await PlaceService.getByID(id)
        if (place) {
            setData(place)
            UserService.viewPlace(place.id);
        } else {
            setData(null);
        }
        setLoading(false)
    }

    const getHeader = () => {

        return (
            <>
                <button className="btn btn-outline-primary my-2 my-sm-0" onClick={e => nav("/")} ><i className="fa-solid fa-house"></i></button>
                <a className="navbar-brand" href="#" style={{ maxWidth: 200, overflow: "hidden" }}>{data?.title ?? " "}</a>
                {data &&
                    <div>


                        <>{
                            data.user_id == user.id &&
                            <button className="btn btn-outline-primary my-2 my-sm-0 me-4" onClick={e => nav(`/place/edit/${data.id}`)} ><i className="fa-solid fa-pen-to-square"></i></button>

                        }

                            <button className="btn btn-outline-primary my-2 my-sm-0" onClick={share}><i className="fa-solid fa-share-nodes" ></i></button>
                        </>
                    </div>
                }

            </>
        )
    }

    const share = () => {
        navigator.share({ url: window.location.href, title: data.title })
    }

    if (loading) {
        return (
            <LoadingDiv></LoadingDiv>
        )
    }

    if (!data) {
        return <div className="p-1"> Địa điểm này không có</div>
    }



    return (

        <div>

            <div className='p-1'>


                <div className="card">

                    <div className="card-body">
                        <h5 className="card-title">Địa chỉ</h5>
                        <div>


                            <div className="mt-1">
                                <i className="fa-solid fa-location-dot me-2"></i> {data.title}
                            </div>
                            <div className="mt-1">
                                <i className="fa-solid fa-road me-2"></i> {data.address}
                            </div>
                            <div className="mt-1">
                                <i className="fa-solid fa-phone  me-2"></i> <a href={`tel:${data.phone_number}`}>{data.phone_number}</a>
                            </div>
                            <div className="mt-1">
                                <i className="fa-regular fa-note-sticky me-2"></i>{data.note}
                            </div>

                        </div>

                        <ImageGird data={data.images} > </ImageGird>



                        <div className='d-flex flex-row-reverse mt-2'>
                            <a href={data.googlemap_link} className="btn btn-primary mt-2" target='_blank'>Mở bản đồ</a>

                        </div>

                    </div>
                </div>

                {data.navigations.map((data, index) => {
                    return (<Navigation data={data} collapse={index > 0} key={`navi_${data.id}`} ></Navigation>);
                })}

                {(id == "Z90bwxbjDV" || id == "UeysixsOnP") &&

                    <div className="mt-2">
                        <div className="m-2 text-center">
                            Hãy tạo một chỉ đường  mới và chia sẻ với bạn bè hoặc shipper.
                        </div>
                        <div className="m-2 text-center">
                            Giúp mọi người tìm đường dễ dàng.
                        </div>

                        <div className="m-2 d-flex  justify-content-center">
                            <span className="btn btn-primary" onClick={e => nav("/place/edit/0")}>Tạo chỉ đường</span>
                        </div>
                    </div>
                }



            </div>





        </div >

    );
}

export default memo(Place);
