import { useState, useEffect, memo, useContext } from "react";
import { AppContext } from "../app";
import { useNavigate } from "react-router-dom";
import * as UserService from "@services/user"
import * as PlaceService from "@services/place"
import LoadingDiv from "@components/loading"
import List from "@components/list-place"

function Home() {
    const nav = useNavigate();
    const { setHeader } = useContext(AppContext)


    const [showSearch, setShowSearch] = useState(false)
    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )


    }, [])

    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
    }, [showSearch])






    const getHeader = () => {

        return (
            <>
                <a className="navbar-brand" href="#">
                    <img className="me-2" src="https://storage.googleapis.com/cubebio/default/map.png" style={{ width: 32, height: 32 }}></img> Tìm kiếm
                </a>

            </>
        )
    }



    return (
        <div className="p-2">

            <SearchBox onClose={e => setShowSearch(false)}>

            </SearchBox>


           
        </div>
    )
}

var searchTimeOut = null;
function SearchBox({ onClose }) {
    const [places, setPlace] = useState([])
    const [loadingList, setLoadingList] = useState(true)
    const [searchForm, setSearchForm] = useState({ info: "" })
    const onInfoChange = (e) => {
        setSearchForm({ ...searchForm, info: e.target.value })
    }

    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        searchTimeOut = setTimeout(async () => {
            await search();
        }, 500);

    }, [searchForm])

    const search = async () => {
        setLoadingList(true);
        var data = await PlaceService.search(searchForm);
        setPlace(data);
        setLoadingList(false)
    }

    return (
        <div>
 
            <div className="input-group mb-3 mt-2">
                <input type="text" className="form-control" placeholder="Tên, hoặc số điện thoại" aria-label="Tên, hoặc số điện thoại" onChange={onInfoChange} value={searchForm.info} />
                <button className="btn btn-outline-primary" type="button" onClick={search}><i className="fa-solid fa-magnifying-glass"></i></button>
            </div>
            <List places={places ?? []} isLoading={loadingList}></List>
        </div>
    )
}




export default Home;