import { useState, useEffect, createContext } from "react";
import Layout from "@components/app/Layout"
import { BrowserRouter } from "react-router-dom";
const AppContext = createContext();

function App() {
    const [header, setHeader] = useState(null);

    const [user, setUser] = useState({});





    return (
        <BrowserRouter  >
            <AppContext.Provider value={{ header, setHeader, user, setUser }}>
                <Layout></Layout>
            </AppContext.Provider>
        </BrowserRouter>
    );
}


export { AppContext }

export default App;
