import api from "@/api"

const getByID = async (id) => {
    var { data } = await api.get("api/place/get", { params: { id: id } });
    if (data.code == 1) {
        return data.data;
    }
    return null;

}

const search = async (searchForm) => {
    var { data } = await api.post("api/place/search", searchForm);
    if (data.code == 1) {
        return data.data;
    }
    return null;

}


export { getByID ,search}





