import api from "@/api"
const logIn = async () => {
    var token = localStorage.getItem("token");
    var { data } = await api.get("/api/me", { params: { token: token } });
    if (data.code == 1) {
        if (data.data.token) {
            localStorage.setItem("token", data.data.token);
        }

        localStorage.setItem("user_id", data.data.user.id);
        return { id: data.data.user.id };
    }
    return false;
}

const logOut = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    return true;
}



export { logIn, logOut }
