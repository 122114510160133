import { useState, useEffect, memo, useContext } from "react";
import { AppContext } from "../app";
import { useNavigate } from "react-router-dom";
import * as UserService from "@services/user"
import * as PlaceService from "@services/place"
import LoadingDiv from "@components/loading"
import List from "@components/list-place"

function Home() {
    const nav = useNavigate();
    const { setHeader } = useContext(AppContext)
    const [places, setPlace] = useState([])
    const [myPlaces, setMyPlaces] = useState([])
    const [loadingMyPlaces, setLoadingMyPlaces] = useState(true)
    const [loadingList, setLoadingList] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
        loadPlaces();


    }, [])

    useEffect(() => {
        var header = getHeader();
        setHeader(
            header
        )
    }, [showSearch])

    const loadPlaces = async () => {
        setLoadingList(true);
        var lst = await UserService.recentlyList({});
        setLoadingList(false);
        if (lst) {
            setPlace(lst);
        }
    }

   



    const getHeader = () => {

        return (
            <>
                <a className="navbar-brand" href="#">
                    <img className="me-2" src="https://storage.googleapis.com/cubebio/default/map.png" style={{ width: 32, height: 32 }}></img>Gần đây
                </a>
                
            </>
        )
    }

    return (
        <div className="p-2">


            <div className="mt-4">
             
                <List places={places ?? []} isLoading={loadingList}></List>


            </div>
        </div >
    )
}

export default Home;