import { useState, useMemo, memo } from "react"
import ImageGird from "@components/image-gird"

const Navigation = ({ data, collapse, index }) => {

    const [divID, setdivID] = useState('carousel' + (Math.round(Math.random() * 10000)))

    if (!data) {

        return null;
    }




    return (


        <div className="card mt-2">
            <div className="card-body">
                <h5 className="card-title">Chỉ đường</h5>
                <div className={collapse ? "collapse" : ""} id={divID} >
                    {data.steps.map((step, index) => {
                        return (
                            <div className='mt-2'>
                                {index + 1}.  {step.title}
                                <ImageGird data={step.images}  >

                                </ImageGird>


                            </div>
                        )
                    })}

                    <div className='mt-2'>
                       
                    </div>




                </div>

                {collapse &&
                    <a className="card-link" data-bs-toggle="collapse" href={`#${divID}`} role="button" aria-expanded="false" aria-controls={`#${divID}`}>
                        Xem
                    </a>
                }


            </div>
        </div>
    )

}

export default memo(Navigation)


