import { memo } from "react"
import { useNavigate } from "react-router-dom";
function Item({ data }) {
    const nav = useNavigate();
    const getImageurl = () => {
        if (data) {
            if (data.images && data.images && data.images[0]) {
                return data.images[0].url;
            }
        }
        return `https://storage.googleapis.com/cubebio/default/map_2.svg`;
    }
    return (
        <div class="card mb-3" onClick={e => nav(`/place/${data.id}`)}>
            <div class="row g-1">
                <div class="col-4 p-2">

                    <img src={getImageurl()} class="img-fluid rounded-start" alt="..." />
                </div>
                <div class="col-8">
                    <div class="card-body">
                        <div class="fs-4 text-truncate">{data.title}</div>
                        <div class="card-text text-truncate">{data.address}</div>
                        <div class="card-text text-truncate">{data.phone_number}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Item);