import { useState, useMemo, memo } from "react"


const ImageGird = ({ data, onDelete }) => {
    const [carouselID, setcarouselID] = useState('carousel' + (Math.round(Math.random() * 10000)))

    if (!data) {

        return null;
    }
    if (data.length <= 0) {

        return null;
    }

    return (






        <div className="row gx-2 gy-2">


            {data.map((item, index) => {

                return (
                    <div className="col-6 col-md-6" style={{ position: "relative" }}>
                        <img src={item.url} style={{ width: "100%", height: "auto", maxHeight: 450 }}></img>
                        <div style={{ position: "absolute", top: 5, right: 10 }}>
                            <span className="btn btn-outline-primary btn-sm" onClick={e => onDelete(index)}><i className="fa-solid fa-trash"></i></span>
                        </div>
                    </div>
                )
            })}


        </div>

    )

}

export default memo(ImageGird)


