import api from "@/api"
const savePlace = async (place) => {
    var { data } = await api.post("api/user/place/save", place);
    if (data.code == 1) {
        return data.data;
    }
    return null;

}

const getPlaceByID = async (id) => {
    var { data } = await api.get("api/user/place/get", { params: { id: id } });
    if (data.code == 1) {
        return data.data;
    }
    return null;

}

const listPlace = async (searchForm) => {
    var { data } = await api.post("api/user/place/list", searchForm);
    if (data.code == 1) {
        return data.data;
    }
    return null;

}

const deletePlace = async (id) => {
    var { data } = await api.post("api/user/place/delete", { id: id });
    if (data.code == 1) {
        return data.data;
    }
    return null;

}


const recentlyList = async (searchForm) => {
    var { data } = await api.post("api/user/place/recentlylist", searchForm);
    if (data.code == 1) {
        return data.data;
    }
    return null;

}


const viewPlace = async (id) => {
    var { data } = await api.post("api/user/place/view", { id: id });
    if (data.code == 1) {
        return data.data;
    }
    return null;

}



export { savePlace, getPlaceByID, listPlace, deletePlace, viewPlace, recentlyList }





